@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200;300;400&display=swap');
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    /* font-family: "Raleway", sans-serif; */
    font-family: "Inconsolata",monospace;

  }
}
